import React from 'react'

function Blogs() {
  return (
    <div style={{ height:'80vh', fontSize:'3rem', display:'flex', justifyContent:'center', padding:'2rem' }}>
      <h1>COMING SOON!</h1>
    </div>
  )
}

export default Blogs
